import { createRoot } from 'react-dom/client';

import * as React from 'react';

import { useState, useEffect, useRef, useLayoutEffect } from 'react';

import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import '@fontsource/inter/latin-400';

import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup';
import ButtonGroup from '@mui/joy/ButtonGroup';
import IconButton from '@mui/joy/IconButton';
import Snackbar from '@mui/joy/Snackbar';
import Alert from '@mui/joy/Alert';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import useMediaQuery from '@mui/material/useMediaQuery';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import ShareIcon from '@mui/icons-material/Share';

import { Tree } from '@yowasp/yosys'

import * as monaco from 'monaco-editor';
import { EditorState, Editor } from './monaco';
import data from './config';

import './app.css';
import { Command, Product, asyncRunner } from './command';
import { runVerilator } from './verilator_yowasp';
import { getFileInTree } from './sim/util';
import { HDLModuleWASM } from './sim/hdlwasm';
import { getVGASignals, setUserIputs, waitFor } from './vga_util';
import { terminal } from './terminal';
import { helloText } from './hello_text';
import Link from '@mui/joy/Link';


function stealHashQuery() {
  const { hash } = window.location;
  if (hash !== '') {
    history.replaceState(null, '', ' '); // remove #... from URL entirely
    const hashQuery = hash.substring(1);
    try {
      const result = JSON.parse(atob(hashQuery))
      console.log(`Got ${result} from query`)
      return result;
    } catch {
    }
  }
}



function handleIostream(s: Uint8Array | string | null, setter: React.Dispatch<React.SetStateAction<string | null>>) {
  let decoder = new TextDecoder()
  if (s != null) {
    if (typeof s === "string") {
      setter((prev) => prev === null ? s : prev + s)
    }
    else {
      let newNow = decoder.decode(s, { stream: true })
      setter((prev) => prev === null ? newNow : prev + newNow)
    }
  }
}

function AppContent() {
  const { mode, setMode } = useColorScheme();
  useEffect(() => monaco.editor.setTheme(mode === 'light' ? 'vs' : 'vs-dark'), [mode]);

  const query: { spadeSource?: string, tomlSource?: string } | undefined = stealHashQuery();
  const [running, setRunning] = useState(false);
  const [activeLeftTab, setActiveLeftTab] = useState('spade-source');
  const [activeRightTab, actualSetActiveRightTab] = useState('tutorial');
  const [sourceEditorState, setSourceEditorState] = useState(new EditorState(
    query?.spadeSource
    ?? localStorage.getItem('spade-playground.source')
    ?? data.demoCode));
  useEffect(() => localStorage.setItem('spade-playground.source', sourceEditorState.text), [sourceEditorState]);

  const [tomlEditorState, setTomlEditorState] = useState(new EditorState(
    query?.tomlSource
    ?? localStorage.getItem('spade-playground.toml')
    ?? data.demoToml));
  useEffect(() => localStorage.setItem('spade-playground.toml', tomlEditorState.text), [tomlEditorState]);
  const [sharingOpen, setSharingOpen] = useState(false);
  const isLargeScreen = useMediaQuery('(min-width: 800px)')

  const canvasRef = useRef<HTMLCanvasElement>();

  const [commandOutput, setCommandOutput] = useState<string | null>(null);
  const [productsOutOfDate, setProductsOutOfDate] = useState(false);
  const [verilogProduct, setVerilogProduct] = useState<string | null>(null);
  const [hdlMod, setHdlMod] = useState<HDLModuleWASM | null>(null);

  const [inputs, setInputs] = useState<number>(0)

  const setActiveRightTab = (tabName: string) => {
    if (isLargeScreen) {
      actualSetActiveRightTab(tabName)
    } else {
      setActiveLeftTab(tabName)
    }
  }


  async function runCommands(commands: Command[], onDone?: () => void) {
    let failed = false;
    if (running)
      return;

    setCommandOutput(null)
    if (hdlMod) {
      hdlMod.dispose()
    }
    setHdlMod(null)
    setActiveRightTab('command-output')

    let files = {
      "src": { "playground.spade": sourceEditorState.text },
      "swim.toml": tomlEditorState.text
    }

    for (const cmd of commands) {
      setRunning(true);
      setProductsOutOfDate(false);

      const handlers = {
        stdout: (s) => handleIostream(s, setCommandOutput),
        stderr: (s) => handleIostream(s, setCommandOutput),
      }

      handlers.stdout(`[Playground] Running ${cmd.name} ${cmd.args}\n`)

      try {
        files = await cmd.runner(cmd.args, files, handlers)

        if (cmd.produces !== null) {
          try {
            cmd.produces.stateUpdater(getFileInTree(files, cmd.produces.file))
          } catch (e) {
            setCommandOutput(prev => prev + e)
          }
        }
      } catch (e) {
        console.log(e)
        handlers.stdout(`[Playground] ${cmd.name} exited with error ${e}\n`)
        setActiveRightTab("command-output")
        failed = true;
        break;
      }
      handlers.stdout(`[Playground] ${cmd.name} done\n`)
    }

    setRunning(false)
    if (!failed && onDone) {
      onDone()
    }
  }

  const swimCommands = [
    new Command(
      "swim-prepare",
      asyncRunner("swimPrepare"),
      [],
      null
    ),
    new Command(
      "swim",
      asyncRunner("swim"),
      ["build"],
      null
    )
  ]

  const spadeCommands = swimCommands.concat([
    new Command(
      "spade",
      asyncRunner("spade"),
      ["--command-file", "build/commands.json", "-o", "build/spade.sv", "dummy_file", "--no-color"],
      new Product(["build", "spade.sv"], "verilog-product", setVerilogProduct)
    )
  ]);

  const simulationCommands = spadeCommands.concat([
    new Command(
      "verilator",
      async (args, files, options) => {
        const res = await runVerilator(args, files, options);

        if (res.output) {
          if (hdlMod) {
            hdlMod.dispose()
          }
          let mod = new HDLModuleWASM(res.output.modules['TOP'], res.output.modules['@CONST-POOL@'])
          await mod.init()
          mod.powercycle()

          mod.state.a = 5;
          mod.state.b = 6;
          mod.tick2(10)
          console.log(mod.state.out)

          setHdlMod(mod)
        } else {
          console.log("No output from verilator")
        }

        return files
      },
      [],
      null
    )
  ])


  const prevSourceCode = useRef(sourceEditorState.text);
  useEffect(() => {
    if (sourceEditorState.text != prevSourceCode.current)
      setProductsOutOfDate(true);
    prevSourceCode.current = sourceEditorState.text;
  }, [sourceEditorState]);

  const [counter, setCounter] = useState(0)
  useLayoutEffect(() => {
    const canvas = canvasRef.current
    const animate = () => {
      setCounter(c => c + 1)
      if (canvas && hdlMod) {
        requestAnimationFrame(animate)
      }
    }
    if (canvas && hdlMod) {
      requestAnimationFrame(animate)
    }
  })

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas && hdlMod) {
      const context = canvas.getContext('2d')

      context.fillStyle = "#000000"
      context.fillRect(0, 0, 800, 480)
      const imageData = context.createImageData(640, 480)

      const data = new Uint8Array(imageData.data.buffer);
      frameLoop: for (let y = 0; y < 480; y++) {
        waitFor(hdlMod, () => !getVGASignals(hdlMod).hsync);

        // Wait for back porch before pixels
        for (let x = 0; x < 48; x++) {
          hdlMod.tick2(1)
        }

        for (let x = 0; x < 640; x++) {
          const offset = (y * 640 + x) * 4;
          hdlMod.tick2(1);
          const { hsync, vsync, r, g, b } = getVGASignals(hdlMod);
          if (hsync) {
            break;
          }
          if (vsync) {
            break frameLoop;
          }
          data[offset] = r;
          data[offset + 1] = g;
          data[offset + 2] = b;
          data[offset + 3] = 0xff;
        }
        waitFor(hdlMod, () => getVGASignals(hdlMod).hsync);
      }
      context!.putImageData(imageData, 0, 0);
      waitFor(hdlMod, () => getVGASignals(hdlMod).vsync);
      waitFor(hdlMod, () => !getVGASignals(hdlMod).vsync);

      // Back porch
      for (let y = 0; y < 33; y++) {
        for (let x = 0; x < 800; x++) {
          hdlMod.tick2(1)
        }
      }
    }
  }, [counter])

  useEffect(() => {
    if (hdlMod) {
      setUserIputs(hdlMod, inputs)
    }
  }, [hdlMod, inputs])


  function tabAndPanel({ key, title, titleStyle = {}, content }) {
    return [
      <Tab key={`${key}-tab`} value={key} style={titleStyle}>{title}</Tab>,
      <TabPanel key={`${key}-tabpanel`} value={key} sx={{ padding: 0 }}>{content}</TabPanel>
    ];
  }

  const rightTabsWithPanels = [
    tabAndPanel({
      key: 'tutorial',
      title: <QuestionMarkIcon />,
      content: helloText()
    }),
    tabAndPanel({
      key: 'command-output',
      title: 'Command output',
      content: terminal(commandOutput)
    }),
    tabAndPanel({
      key: 'canvas',
      title: 'VGA/HDMI Output',
      content:
        <Box display="flex" flexDirection="column">
          <Box sx={{ width: 640, height: 480 }}>
            <canvas ref={canvasRef}
              width="640px"
              height="480px" />
          </Box>
          <Box>
            <span>Input toggles:</span>
            {
            // Cursedness: 87%. The ToggleButtonGroup looks pretty, but it works with strings,
            // one for each button, not an array of pressed buttons or anything. We'll work
            // around that by generating buttons with the correct 'value' string for each digit,
            // then translate between an array of strings like ["1", "3", "4"] and the binary repr
            // in this case 0b0101_1000
            }
            <ToggleButtonGroup
              value={[...Array(8).keys()]
                .filter((num) => {
                  return ((inputs >> num) & 1) == 1
                })
                .map((num) => num.toString())
              }
              onChange={
                (_, newValue) => {
                  let newValueInt = 0
                  for (const digit of newValue) {
                    newValueInt = newValueInt | (1 << parseInt(digit))
                  }
                  setInputs(newValueInt)
                }
              }
            >
              {[...Array(8).keys()].map((num) => {
                return <Button value={num.toString()}>{num}</Button>
              })}
            </ToggleButtonGroup>
            <span>Input buttons:</span>
            <ButtonGroup>
              {[...Array(8).keys()].map((num) => {
                return <Button
                    onPointerDown={() => {
                      setInputs(inputs | (1 << num))
                    }}
                    onPointerUp={() => {
                      setInputs(inputs & ~(1 << num))
                    }}
                  >{num}</Button>
              })}
            </ButtonGroup>
          </Box>
        </Box>
    }),
  ];

  const leftTabsWithPanels = [
    tabAndPanel({
      key: 'spade-source',
      title: 'playground.spade',
      content: <Editor
        padding={isLargeScreen ? { top: 10, bottom: 10 } : {top: 0, bottom: 0}}
        language='spade'
        state={sourceEditorState}
        setState={setSourceEditorState}
        focus
      />
    }),
    tabAndPanel({
      key: 'toml-source',
      title: 'swim.toml',
      content: <Editor
        padding={isLargeScreen ? { top: 10, bottom: 10 } : {top: 0, bottom: 0}}
        language='toml'
        state={tomlEditorState}
        setState={setTomlEditorState}
        focus
      />
    }),
  ].concat(isLargeScreen ? [] : rightTabsWithPanels)



  function maybeEditorTab(product: string | null, key: string, title: string, language: string) {
    if (product !== null)
      rightTabsWithPanels.push(tabAndPanel({
        key: key,
        title: title,
        titleStyle: productsOutOfDate ? { textDecoration: 'line-through' } : {},
        content:
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            {productsOutOfDate && <Alert variant='soft' color='warning' sx={{ borderRadius: 0 }}>
              The generated Verilog is out of date. Run the program again to refresh it.
            </Alert>}
            <Box sx={{ flexGrow: 1 }}>
              <Editor
                padding={isLargeScreen ? { top: 10, bottom: 10 } : {top: 0, bottom: 0}}
                language={language}
                state={new EditorState(product)}
                focus
              />
            </Box>
          </Box>
      }));
  }

  maybeEditorTab(verilogProduct, "verilog-product", "Generated Verilog", "verilog")

  return <>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100vw',
      height: '100vh',
      padding: isLargeScreen ? 2 : 1,
      gap: isLargeScreen ? 2 : 1
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: isLargeScreen ? 2 : 1
      }}>

        <Button
          size='lg'
          sx={{ borderRadius: 10 }}
          variant='outlined'
          startDecorator={<PlayArrowIcon />}
          loading={running}
          onClick={() => runCommands(spadeCommands)}
        >
          Build
        </Button>

        <Button
          size='lg'
          sx={{ borderRadius: 10 }}
          variant='outlined'
          startDecorator={<PlayArrowIcon />}
          loading={running}
          onClick={() => runCommands(simulationCommands, () => setActiveRightTab('canvas'))}
        >
          Simulate
        </Button>

        {/* spacer */} <Box sx={{ flexGrow: 1 }} />
        <Button
          size='lg'
          sx={{ borderRadius: 10 }}
          color='neutral'
          variant='outlined'
          endDecorator={<ShareIcon/>}
          onClick={() => setSharingOpen(true)}
        >
          Share
        </Button>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={sharingOpen}
          onClose={(_event, _reason) => setSharingOpen(false)}
        >
          <Link href={
            // base64 overhead is fixed at 33%, urlencode overhead is variable, typ. 133% (!)
            new URL('#' + btoa(JSON.stringify({
              spadeSource: sourceEditorState.text, tomlSource: tomlEditorState.text,
            })), window.location.href).toString()
          }>
            Copy this link to share the source code
          </Link>
        </Snackbar>

        <IconButton
          size='lg'
          sx={{ borderRadius: 10 }}
          variant='outlined'
          onClick={() => setMode(mode === 'light' ? 'dark' : 'light')}
        >
          {mode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
        </IconButton>

      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        height: "95%",
        padding: isLargeScreen ? 2 : 1,
        gap: isLargeScreen ? 2 : 1
      }}>

        <Tabs
          sx={{ height: '100%', width: isLargeScreen ? '50%' : "100%" }}
          value={activeLeftTab}
          onChange={(_event, value) => setActiveLeftTab(value as string)}
        >
          <TabList>{leftTabsWithPanels.map(([tab, _panel]) => tab)}</TabList>
          {leftTabsWithPanels.map(([_tab, panel]) => panel)}
        </Tabs>

        {isLargeScreen
          ? 
            <Tabs
              sx={{ height: '100%', width: '50%' }}
              value={activeRightTab}
              onChange={(_event, value) => setActiveRightTab(value as string)}
            >
              <TabList>{rightTabsWithPanels.map(([tab, _panel]) => tab)}</TabList>
              {rightTabsWithPanels.map(([_tab, panel]) => panel)}
            </Tabs>
          : <div></div>
        }
      </Box>
    </Box>
  </>;
}

createRoot(document.getElementById('root')!).render(
  <CssVarsProvider>
    <CssBaseline />
    <AppContent />
  </CssVarsProvider>
);

console.log('Build ID:', globalThis.GIT_COMMIT);

// https://esbuild.github.io/api/#live-reload
if (!globalThis.IS_PRODUCTION)
  new EventSource('/esbuild').addEventListener('change', () => location.reload());
